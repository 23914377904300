<template>
  <div class="content-container reservation-container page-wrap">
    <section class="reservation-intro">
      <h1 class="heading-1">Rezervace</h1>
      <p class="desc">Pro rezervaci vyplňte rezervační formulář níže (volné termíny si můžete vybrat podle kalendáře). Po odeslání formuláře vás budeme kontaktovat.</p>
      <p class="desc">Do jednoho týdne od potvrzení rezervace je nutná úhrada zálohy ve výši 50% z celkové ceny ubytování. V případě, že tato záloha nebude ani po upomínkách řádně uhrazena, rezervace bude zrušena. Dojde-li ke stornování ubytování, záloha ve všech případech propadá ve prospěch Jupiterwellness a zaniká nárok na její vrácení. Při změně termínu do 14 dní před příjezdem je záloha platná v novém termínu.</p>

      <form class="reservation-form" action="#" method="post" @submit.prevent="processForm">
        <div class="form-element">
          <label for="name">Jméno a příjmení</label>
          <input type="text" name="name" id="name" v-model="name" placeholder="Vaše jméno" />
          <span class="error" v-show="errorName">Vyplňte prosím vaše jméno</span>
        </div>
        <div class="form-element">
          <label for="email">Email</label>
          <input type="email" name="email" id="email" v-model="email" placeholder="Na tento email vám potvrdíme rezervaci" />
          <span class="error" v-show="errorEmail">Vyplňte prosím váš email</span>
        </div>
        <div class="form-element">
          <label for="email">Telefon</label>
          <input type="tel" name="phone" id="phone" v-model="phone" placeholder="+420123456789" />
          <span class="error" v-show="errorEmail">Vyplňte prosím váše telefonní číslo</span>
        </div>
        <div class="form-group-wrapper">
          <div class="form-group">
            <div class="form-element">
              <label for="date-from">Příjezd</label>
              <datetime class="theme-custom" v-model="dateFrom" :phrases="{ok: 'Potvrdit', cancel: 'Zrušit'}" input-id="date-from"
                :min-datetime="nowISODate" :max-datetime="dateTo" placeholder="Datum příjezdu">
                <template slot="before">
                  <svg class="icon" width="100%" height="100%" viewBox="0 0 12 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
                    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                    <g id="calendar" transform="matrix(1,0,0,1,-16,-13)">
                      <path d="M19,15.545L17.8,15.545C17.469,15.545 17.2,15.83 17.2,16.182L17.2,18.091L26.8,18.091L26.8,16.182C26.8,15.83 26.531,15.545 26.2,15.545L25,15.545L25,16.182C25,16.533 24.731,16.818 24.4,16.818C24.069,16.818 23.8,16.533 23.8,16.182L23.8,15.545L20.2,15.545L20.2,16.182C20.2,16.533 19.931,16.818 19.6,16.818C19.269,16.818 19,16.533 19,16.182L19,15.545ZM20.2,14.273L23.8,14.273L23.8,13.636C23.8,13.285 24.069,13 24.4,13C24.731,13 25,13.285 25,13.636L25,14.273L26.2,14.273C27.194,14.273 28,15.127 28,16.182L28,25.091C28,26.145 27.194,27 26.2,27L17.8,27C16.806,27 16,26.145 16,25.091L16,16.182C16,15.127 16.806,14.273 17.8,14.273L19,14.273L19,13.636C19,13.285 19.269,13 19.6,13C19.931,13 20.2,13.285 20.2,13.636L20.2,14.273ZM26.8,19.364L17.2,19.364L17.2,25.091C17.2,25.442 17.469,25.727 17.8,25.727L26.2,25.727C26.531,25.727 26.8,25.442 26.8,25.091L26.8,19.364Z"
                        style="fill-rule:nonzero;" />
                    </g>
                  </svg>
                </template>
              </datetime>
            </div>
            <div class="form-element">
              <label for="date-to">Odjezd</label>
              <datetime class="theme-custom" v-model="dateTo" :phrases="{ok: 'Potvrdit', cancel: 'Zrušit'}" input-id="date-to" :min-datetime="dateFrom || nowISODate"
                placeholder="Datum odjezdu">
                <template slot="before">
                  <svg class="icon" width="100%" height="100%" viewBox="0 0 12 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
                    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                    <g id="calendar" transform="matrix(1,0,0,1,-16,-13)">
                      <path d="M19,15.545L17.8,15.545C17.469,15.545 17.2,15.83 17.2,16.182L17.2,18.091L26.8,18.091L26.8,16.182C26.8,15.83 26.531,15.545 26.2,15.545L25,15.545L25,16.182C25,16.533 24.731,16.818 24.4,16.818C24.069,16.818 23.8,16.533 23.8,16.182L23.8,15.545L20.2,15.545L20.2,16.182C20.2,16.533 19.931,16.818 19.6,16.818C19.269,16.818 19,16.533 19,16.182L19,15.545ZM20.2,14.273L23.8,14.273L23.8,13.636C23.8,13.285 24.069,13 24.4,13C24.731,13 25,13.285 25,13.636L25,14.273L26.2,14.273C27.194,14.273 28,15.127 28,16.182L28,25.091C28,26.145 27.194,27 26.2,27L17.8,27C16.806,27 16,26.145 16,25.091L16,16.182C16,15.127 16.806,14.273 17.8,14.273L19,14.273L19,13.636C19,13.285 19.269,13 19.6,13C19.931,13 20.2,13.285 20.2,13.636L20.2,14.273ZM26.8,19.364L17.2,19.364L17.2,25.091C17.2,25.442 17.469,25.727 17.8,25.727L26.2,25.727C26.531,25.727 26.8,25.442 26.8,25.091L26.8,19.364Z"
                        style="fill-rule:nonzero;" />
                    </g>
                  </svg>
                </template>
              </datetime>
            </div>
          </div>
          <span class="error" v-show="errorDate">Vyplňte prosím datum příjedu a odjezdu</span>
        </div>
        <div class="form-element">
          <label for="message">Kalendář <span>(Náhled volných termínů)</span></label>
          <iframe src="https://obsazenost.e-chalupy.cz/kalendar.php?id=2472&legenda=ne&jednotky=ano&pocetMesicu=24&velikost=3" width="100%" height="260" frameborder="0" id="echalupy-kalendar"></iframe>
        </div>
        <div class="form-element">
          <label for="message">Speciální požadavky <span>(Volitelné)</span></label>
          <textarea name="message" id="message" cols="30" rows="10" v-model="message" placeholder="Máte dodatečné požadavky nebo přání? Napište je zde"></textarea>
          <span>Odeslaním rezervace souhlasíte s <a href="https://jupiterwellness.cz/doc/jupiter-gdpr.pdf" class="link" rel="nofollow" target="_blank">ochranou osobních údajů</a> a <a href="https://jupiterwellness.cz/doc/storno-podminky.pdf?v=3" class="link" rel="nofollow" target="_blank">storno podmínkami</a>.</span>
        </div>

        <div class="state-message" :class="flashMessageClass" v-show="isFlashMessageVisible">{{ flashMessageText }}</div>

        <input type="submit" value="Rezervovat" class="button" :disabled="isSubmitting" />
      </form>
    </section>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import { Settings } from 'luxon'
Settings.defaultLocale = 'cs'

export default {
  name: "Reservation",
    components: {
      datetime: Datetime,
    },
    metaInfo: {
      title: 'Rezervace',
      titleTemplate: '%s | Jupiter Wellness',
      meta: [
      {
        'property': 'og:title',
        'content': 'Rezervace',
        'template': '%s | Jupiter Wellness',
        'vmid': 'og:title'
      },
      {
        'property': 'og:url',
        'content': 'https://jupiterwellness.cz/rezervace',
        'vmid': 'og:url'
      },
    ]
    },
  data: function () {
    return {
      name: '',
      email: '',
      phone: '',
      dateFrom: '',
      dateTo: '',
      message: '',
      isSubmitting: false,
      isFlashMessageVisible: false,
      flashMessageText: '',
      flashMessageClass: '',
      errorName: false,
      errorEmail: false,
      errorPhone: false,
      errorDate: false,
    };
  },
  mounted() {

  },
  methods: {
    showFlashMessage(message, success) {
      this.flashMessageText = message;
      this.flashMessageClass = success ? 'success' : 'error';
      this.isFlashMessageVisible = true;
    },
    processForm() {
      if (!this.name) {
        this.errorName = true;
        return false;
      }

      if (!this.email) {
        this.errorEmail = true;
        return false;
      }

      if (!this.phone) {
        this.errorPhone = true;
        return false;
      }

      if (!this.dateFrom || !this.dateTo) {
        this.errorDate = true;
        return false;
      }

      this.isSubmitting = true;

      return fetch(`/m/m.php`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: this.name,
        email: this.email,
        phone: this.phone,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        message: this.message,
      })
    }).then(response => response.json()).then(returnData => {
      if (returnData.success) {
        this.name = '';
        this.email = '';
        this.phone = '';
        this.dateFrom = '';
        this.dateTo = '';
        this.message = '';
        this.errorName = false;
        this.errorEmail = false;
        this.errorPhone = false;
        this.errorDate = false;
        this.isSubmitting = false;
        this.showFlashMessage('Požadavek na rezervaci byl úspěšně odeslán.', true);
      } else {
        this.isSubmitting = false;
        this.showFlashMessage('Při odesílání požadavku na rezervaci došlo k chybě.', false);
      }
    });
    }
  },
  computed: {
    nowISODate() {
      let now = new Date();

      return now.toISOString();
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";

#obal {
  display: flex;
    overflow: auto;
}

.state-message {
  color: #000;
  margin: rem(25) 0;
  text-align: left;

  &.success {
    color: #0fa728;
  }

  &.error {
    color: #a70f0f;
  }
}

.reservation-form {
  max-width: rem(600);
  margin: 0 auto;
  padding-bottom: rem(20);
}


.form-group-wrapper {
  margin: 0 0 rem(24) 0;
}

.error {
  font-size: rem(14);
  font-weight: 500;
  line-height: 1.14;
  color: #f47e66;
  display: flex;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: row;

  .error {
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.14;
    color: #f47e66;
    display: flex;
    width: 100%;
  }

  .form-element {
    margin: 0 rem(8) 0 0;

    @include breakpoint(tablet) {
      width: 50%;
    }


    &:last-child {
      margin: 0;
    }
  }
}

.form-element {
  margin: 0 0 rem(24) 0;
  text-align: left;

  .error {
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.14;
    color: #f47e66;
  }
}

label {
  font-size: rem(13);
  font-weight: 500;
  line-height: 1.54;
  color: #787469;
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 rem(12) 0;
  display: block;

  span {
    text-transform: none;
  }
}

input,
textarea {
  color: #212123;
  width: 100%;
  height: 44px;
  background-color: #e3dcd180;
  border: none;
  padding: 0 rem(8);
  border: 1px solid transparent;
  margin: 0 0 rem(8) 0;

  &:focus {
    box-shadow: inset 0 0 8px 0 #7874694c;
    background-color: #e3dcd1;
    border: 1px solid #e3dcd1;
  }

  &:placeholder {
    color: #78746980;
  }
}

textarea {
  height: 120px;
  background-color: #e3dcd180;
  padding: rem(9) rem(8);

}

.vdatetime {
  .icon {
    position: absolute;
    top: 50%;
    margin-top: rem(-12);
    margin-left: rem(16);
    fill: #787469;
  }

  input {
    padding-left: rem(40);
  }
}

.reservation-container {
  .reservation-intro {
    text-align: center;
    max-width: rem(682);
    margin: 0 auto;

    .desc {
      font-size: 14px;
      line-height: 1.71;
      text-align: center;
      color: #48484a;
      margin: 0 0 rem(36) 0;
    }

    .link {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .button {
    background-color: #212123;
    color: #fff;
    box-shadow: none;
    height: rem(52);
    transition: background-color .2s ease-in-out;

    &:hover {
      background-color: #48484a;
    }

    @include breakpoint(tablet) {
      width: rem(220);
      margin-left: 0;
    }
  }

  .theme-custom .vdatetime-popup__header,
.theme-custom .vdatetime-calendar__month__day--selected > span > span,
.theme-custom .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #212123;
}

.theme-custom .vdatetime-popup__actions__button {
  color: #212123;

  &:hover {
    color: #48484a;
  }
}
}
</style>
