<template>
  <div class="content-container contact-container page-wrap">
    <section class="contact-intro">
      <h1 class="heading-1">Kontakt</h1>
      <p class="desc">Potřebujete-li poradit nebo máte jakýkoli dotaz, neváhejte se nám ozvat na uvedené kontakty.</p>
    </section>

    <section class="contact-parts">
      <article class="contact-part">
        <h2 class="heading-2">Adresa</h2>
        <p class="desc">
          Jupiter wellness<br>
          Alšovice 220,<br>
          46821 Pěnčín
        </p>
      </article>


      <article class="contact-part">
        <h2 class="heading-2">Kontakt</h2>
        <p class="desc">
          +420 733 120 972<br>
          info@jupiterwellness.cz
        </p>
      </article>


      <article class="contact-part">
        <h2 class="heading-2">Dokumenty</h2>
         <p class="desc">
        <a href="https://jupiterwellness.cz/doc/jupiter-gdpr.pdf" class="link" rel="nofollow" target="_blank">Ochrana osobních údajů</a>
        <br/>
        <a href="https://jupiterwellness.cz/doc/storno-podminky.pdf?v=3" class="link" rel="nofollow" target="_blank">Pojištění pobytu a storno podmínky voucheru</a>
        </p>
      </article>
    </section>

    <section class="map-container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.2691060865423!2d15.233597915739606!3d50.67782967950739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470eb5f6c4cb1273%3A0x257600c7ba0fd588!2zQWzFoW92aWNlIDIyMCwgNDY4IDIxIFDEm27EjcOtbiwgVHNjaGVjaGllbg!5e0!3m2!1sde!2sde!4v1547070402548"
          width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
      </section>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {
  
  },
  metaInfo: {
      title: 'Kontakt',
      titleTemplate: '%s | Jupiter Wellness',
      meta: [
      {
        'property': 'og:title',
        'content': 'Kontakt',
        'template': '%s | Jupiter Wellness',
        'vmid': 'og:title'
      },
      {
        'property': 'og:url',
        'content': 'https://jupiterwellness.cz/kontakt',
        'vmid': 'og:url'
      },
    ]
    },
  data: function () {
    return {

    };
  },
  mounted() {

  },
  methods: {
  },
  computed: {

  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";

.contact-container {
  .contact-intro {
    text-align: center;

    .desc {
      font-size: 14px;
  line-height: 1.71;
  text-align: center;
  color: #48484a;
  margin: 0 0 rem(32) 0;
    }
  }

  .contact-parts {
    @include breakpoint(tablet) {
      display: flex;
      justify-items: space-between;
      justify-content: space-between;
      width: percent(600, 768);
      margin: 0 auto rem(60) auto;
    }
  }

  .contact-part {
    margin: 0 0 rem(24) 0;
            text-align: center;



    @include breakpoint(tablet) {
      text-align: left;
      .heading-2 {
        text-align: left;
      }

   

      
}

    &:last-of-type {
      margin: 0 0 rem(32) 0;
    }
  }

  .map-container {
    height: rem(192);
    margin: 0 0 rem(20) 0;

    @include breakpoint(tablet) {
      height: rem(320);
      width: percent(600, 768);
      margin: 0 auto rem(180) auto;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

</style>